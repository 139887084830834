.searchInput {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.lineCheckbox {
  top: -4px;
  left: -9%;
}

.lineCheckbox label::after {
  top: 5px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.lineAllCheckbox {
  display: block !important;
  width: 24px !important;
  height: 24px !important;
  top: -5px !important;
  left: 15% !important;
}

.lineAllCheckbox label::after {
  top: 6px !important;
  left: 3px !important;
  color: #0e9354 !important;
}

.lineAllCheckbox label::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 100% !important;
  border: 1px solid #0e9354 !important;
}

.lineCheckboxMinus {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #0e9354;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -5px;
  left: 15%;
}

.minusIcon {
  display: flex !important;
  padding-top: 2px !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0e9354 !important;
}

.avatarsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.avatarsContainer span {
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px;
  font-weight: 400;
}

.avatarHolder {
  height: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  color: white;
}

.avatarsContainer .avatarHolder:nth-child(n + 2) {
  margin-left: -10px;
}

:global(.ui.button).addButton {
  background-color: var(--color-green) !important;
}

:global(.ui.basic.green.button).deleteBtn {
  color: #b00020 !important;
}

.method {
  color: #212121 !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 33px !important;
  text-align: left !important;
  justify-content: flex-start !important;
  gap: 16px;
}

:global(.ui.secondary.pointing.menu).tabsMenu {
  display: flex;
  align-items: flex-end;
  background: #fff;
  margin-bottom: 0;
  border-bottom: 1px solid #dededf !important;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item) {
  width: 25%;
  display: flex;
  justify-content: space-between;
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-align: center;
  color: #666666;
}

:global(.ui.secondary.pointing.menu).tabsMenu :global(.item.active) {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin: 0px !important;
  border-color: var(--color-green);
  padding-top: 4px !important;
  padding-bottom: 8px !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-align: center;
  color: #0e9354;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button) {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
  border: none;
  position: relative;
  width: 48%;
}

:global(.ui.basic.buttons).buttonsGroup
  :not(div)
  + :global(.ui.button:not(:first-child):before),
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button:not(:nth-child(-n + 2)):before) {
  position: absolute;
  content: "";
  left: -2px;

  top: 50%;
  margin-top: -10px;
  height: 20px;
  width: 1px;
  background: var(--color-light-grey);
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: var(--color-green) !important;
  width: 48% !important;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive:before,
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button).buttonActive
  + :global(.ui.button):before {
  display: none;
}

:global(.ui.basic.buttons).buttonsGroup div :global(.ui.pointing.label) {
  width: max-content;
}

.progressLineContainer {
  width: 100%;
}

.progressLine {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  width: 0;
  background-color: #f0ac47;
  transition: width 0.3s ease-in-out;
  /* Optional: Add smooth animation */
}

.precent {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #666666;
}

.stepLoading {
  width: 300px;
  height: 48px;
  background: #f5f7f7;
  display: flex;
  align-items: center !important;
}

.iconAreasLabelWrapper {
  width: 115px;
  display: flex !important;
  justify-content: center !important;
}

.dotIcon {
  color: #59b655;
}

.dotIconLabel {
  color: #59b655;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.iconLabelWrapper {
  width: 145px;
  display: flex !important;
  justify-content: center !important;
}

.outLineCircel {
  color: #dededf;
}

.outLineCircel::before {
  color: #dededf;
}

.outLineCircelLabel {
  color: #666666;
  padding-left: 12px;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.hrLine {
  border: 1px dashed #b3b3b3;
  width: 40px !important;
}

.coplitedLine {
  border: 1px solid #0e9354 !important;
}

.employeesLabelContainer {
  margin-bottom: 16px;
}

.workersRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.workersRowHead {
  font-family: "Roboto" !important;
  width: 90px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.addedItemValue {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
