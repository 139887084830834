.tableContainer {
  /*   max-height: 500px;
  overflow-y: auto; */
  margin-top: 20px;
}

.areaTable {
  font-family: 'Roboto' !important;
  width: 100% !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
  border: none !important;
  border-spacing: 8px !important;
}

.areaTable th {
  border: none !important;
  background: none !important;
}


.header {
  width: 25%;
  padding-left: 0px !important;
  background-color: white !important;
  border: none !important;
}

.areaTableBodyRow {
  height: 40px;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  border-radius: 8px !important;
}

.areaTableBodyBlockCell {
  width: 25% !important;
  padding: 0px !important;
  border-top: none !important;
  padding-left: 16px !important;
}

.deleteIcon {
  cursor: pointer;
  color: #B00020 !important;
}

.cleatAll {
  cursor: pointer;
  color: #B00020 !important;
}

.blockPatchWrapper {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.iconCell {
  display: flex;
  justify-content: flex-end;
  padding-right: 25px !important;
}

.iconBodyCell {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  padding-right: 40px !important;
}