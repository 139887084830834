.headerWrapper {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.header {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
}

.icon {
  cursor: pointer;
  color: #666666 !important;
}

.actionButtons {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.modalContentContainer {
  min-height: 600px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.disabledField {
  background-color: #dededf !important;
}

.contentHeader {
  margin-bottom: 16px;
  margin-top: 32px;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  text-align: left !important;
  padding-bottom: 8px;
  border-bottom: 1px solid #dededf;
}

.dropdownCustomStyle {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dropdownCustomStyle :global(.menu) {
  max-height: 250px;
  overflow-y: auto !important;
}

.dropdownCustomStyle :global(.menu) > * {
  background-color: white !important;
  display: flex !important;
  white-space: break-spaces;
  width: 300px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.dropdownCustomStyle :global(.menu) > .item :hover {
  background-color: rgb(237, 236, 236) !important;
}

.dropdownCustomStyle :global(.label) {
  display: none !important;
}

.dropdownCustomStyle > :global(.label ~ .text) {
  display: contents !important;
}

.areasRow {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 5px !important;
  margin-bottom: 10px !important;
}

.areasRowHead {
  font-family: "Roboto" !important;
  width: 50px !important;
  padding-right: 8px;
  padding-left: 8px;
}

.formLabel {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.06px !important;
  text-align: left !important;
  color: #666666 !important;
}

.addedItem {
  display: flex !important;
  align-items: center !important;
  background: #f5f7f7 !important;
  border-radius: 20px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  padding: 5px 12px !important;
  color: #212121 !important;
}

.calendarWrapper {
  display: flex;
  align-items: center;
}

.expirCalendar {
  padding-left: 15px;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-color: transparent !important;
}

.expirCalendar input {
  border: none !important;
}

.calendarIcon {
  padding-right: 15px;
  padding-top: 10px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  height: 40px !important;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important;
}

.searchInput {
  height: 28px !important;
  width: 231px;
  border-radius: 25px !important;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.searchInput input {
  padding: 3px 6px 3px 16px !important;
}

.searchInput input::placeholder {
  color: #b3b3b3;
}

.content {
  padding: 16px;
}

:global(.ui.basic.buttons).buttonsGroup {
  margin-left: 18px;
  width: 400px;
  height: 32px;
  display: flex;
  align-items: center;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button) {
  border-radius: 3px !important;
  margin: 2px;
  color: var(--color-input) !important;
  padding: 9px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
  border: none;
  position: relative;
  width: 30%;
}

:global(.ui.basic.buttons).buttonsGroup
  :not(div)
  + :global(.ui.button:not(:first-child):before),
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button:not(:nth-child(-n + 2)):before) {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  margin-top: -10px;
  height: 20px !important;
  width: 1px;
  background: var(--color-light-grey);
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive {
  box-shadow: 0 0 0 1px var(--color-green) inset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: var(--color-green) !important;
  width: 30% !important;
  height: 28px !important;
  display: flex;
  align-items: center;
}

:global(.ui.basic.buttons).buttonsGroup :global(.ui.button).buttonActive:before,
:global(.ui.basic.buttons).buttonsGroup
  :global(.ui.button).buttonActive
  + :global(.ui.button):before {
  display: none;
}

:global(.ui.basic.buttons).buttonsGroup div :global(.ui.pointing.label) {
  width: max-content;
}

.serchRow {
  display: flex;
  align-items: center;
}

.btnContentWrapper {
  width: 100%;
}

.contentSection {
}

.wrapper {
  border: 1px solid #dededf;
  height: 208px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px 0px 0px 0px;
  overflow-y: auto;
  margin-top: 16px !important;
}

.contractorWrapper {
  border: 1px solid #dededf;
  height: 208px;
  padding: 4px 16px 4px 8px;
  border-radius: 4px 0px 0px 0px;
  overflow-y: auto;
  margin-top: 16px !important;
}

.employeeRow {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.groupRow {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.checkBox label::after {
  border: 1px solid rgba(14, 147, 84, 1) !important;
  color: rgba(14, 147, 84, 1) !important;
  border-radius: 3px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.segment {
  margin-left: 10px;
  width: 98%;
  margin-left: 10px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  min-height: 60px !important;
  box-shadow: 0px 1px 4px 0px #00000026 !important;
  height: auto !important;
}

.rowFieldWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.iconRowField {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  cursor: pointer;
}

.deleteIcon {
  color: #b00020;
}

.employeeIconWrapper {
  display: flex;
  gap: 46px;
}

.rowChevronIcon {
  cursor: pointer;
  color: #666666;
  padding-left: 16px;
}

.openRows {
  padding-top: 16px;
  padding-left: 16px;
}

.subRows {
  padding: 8px;
  padding-left: 35px;
}

.minusIconWrapper {
  width: 17px !important;
  height: 17px !important;
  gap: 0px !important;
  border-radius: 2px !important;
  border: 1px solid #dededf !important;
  opacity: 0px !important;
  color: #0e9354 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.minusIcon {
  margin-bottom: 3px;
}

.minusIcon::before {
  font-size: 9px !important;
}

.nameRowField {
  min-width: 250px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.dropdownCustomStyle div {
  color: #212121 !important;
}

.notSelectedIconWrapper {
  background-color: #9dacba;
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedIconWrapper {
  background-color: #59b655;
  width: 50px;
  height: 50px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notSelectedIcon {
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 100%;
}

.selectedIcon {
  width: 24px !important;
  height: 24px !important;
  background-color: white;
  border-radius: 100%;
  color: #59b655;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.errorMessage {
  color: #b00020;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.dropdownCustomStyle input {
  padding-top: 8px !important;
}

.search {
  display: block !important;
}
