.calendarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.calendarContainer table,
.calendarContainer th,
.calendarContainer td,
.calendarContainer tr {
  border: none;
}

.fc {
  font-family: Arial, sans-serif;
}

.fc-toolbar {
  background-color: #f8f9fa;
  padding: 10px;
}

.fc-header-toolbar {
  background-color: white !important;
  margin-bottom: 0 !important;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 5px 0;
}

.fc-view-container {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
}

.fc-toolbar-title {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #666666 !important;
}

.fc-timeline-event {
  padding: 0px !important;
  height: 53px !important;
  background-color: none !important;
  border: none !important;
  border-radius: 8px;
}

.fc-event-main {
  color: #59b655 !important;
}

.fc-timeline-slot {
  border-color: #dee2e6;
}

.fc-resource {
  color: #666666;
  /* height: 53px !important; */
}

.fc-license-message {
  display: none !important;
}

.fc-timeline-slot-frame a {
  color: #666666 !important;
}

.fc-button-primary {
  background-color: white !important;
  color: #666666 !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 0px !important;
}

.fc-customToday-button {
  background-color: white !important;
  color: #0e9354 !important;
  border: none !important;
  margin-left: 4px !important;
}

.fc .fc-button-active {
  background-color: white !important;
  color: #0e9354 !important;
  border: 1px solid #0e9354 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.fc-customNext-button {
  margin-left: 4px !important;
}

.fc-button-group {
  padding: 2px;
  border: 1px solid #dededf;
  border-radius: 4px;
  min-width: 150px;
}

.fc-datagrid-cell-cushion {
  padding: 0px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-scroller-harness {
  border-bottom: 1px solid #dededf !important;
}

.fc-datagrid-body > tbody > tr > td .fc-datagrid-cell-frame {
  border-bottom: 1px solid #dededf;
}

.fc-datagrid-body > tbody > tr:last-child td .fc-datagrid-cell-frame {
  border-bottom: none !important;
}

.fc-timeline-body > .fc-timeline-slots > table > tbody > tr > td {
  border-left: 1px solid #dededf !important;
}

.fc-datagrid-cell-frame {
  height: 53px !important;
}

.fc-scrollgrid-sync-table tbody tr {
  height: 53px !important;
}

.fc-timeline-lane-frame {
  height: 53px !important;
}

.resourceWrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 53px;
}

.taskNameContainer {
  padding-left: 12px;
}

.taskNameWrapper {
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 301px;
}

.taskType {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #212121 !important;
}

.taskId {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #0e9354 !important;
}

.taskName {
  margin: 0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.precentSection {
  padding-left: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.fc-h-event {
  background-color: white !important;
}

.fc-day-sat,
.fc-day-sun {
  background-color: #f6f7f8 !important;
}

.fc-col-header-cell[data-day="6"],
.fc-col-header-cell[data-day="0"] {
  background-color: #f6f7f8;
}

.fc .fc-timeline-slot-frame {
  height: 53px !important;
}

.redirectLink {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.fc-theme-standard
  .fc-scrollgrid-sync-table
  .fc-day-today
  .fc-col-header-cell-cushion {
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: auto;
}

.fc-day-today div a {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  height: 35px !important;
  background-color: #59b655 !important;
  color: white !important;
  border-radius: 100%;
}

.fc-scrollgrid-section-body .fc-day-today div {
  height: 100%;
  width: 1%;
  margin-left: 50%;
  background-color: #59b655;
}

.fc-scrollgrid thead {
  background-color: white !important;
  margin-bottom: 0 !important;
  position: sticky !important;
  top: 50px !important;
  z-index: 10 !important;
}
