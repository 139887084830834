.menuContainer {
  overflow-x: hidden;
}

.dropdownCustomStyle {
  border: 1px solid #dededf !important;
  padding: 0px 8px 0px 8px !important;
  gap: 8px !important;
  border-radius: 3px !important;
  width: 100% !important;
  height: 39px !important;
}

.dropdownCustomStyle :global(.menu) > * {
  white-space: break-spaces;
  width: 300px;
  overflow-x: hidden;
}

.dropdownCustomStyle input {
  display: none !important;
}

.dropdownCustomStyle div {
  color: #212121 !important;
}

.searchInput {
  margin: 0 !important;
  width: 100% !important;
  border-bottom: 1px solid #dededf !important;
}

.searchInput input {
  display: block !important;
  width: 95% !important;
  margin: 10px !important;
  padding-left: 10px !important;
}

.searchInput i {
  margin-left: 10px !important;
}

.dropdonwIcon {
  position: absolute;
  left: 93%;
  top: 35%;
}
