@import "../../../../../assets/css/variables.css";

.chemicalHolder {
  padding: 0 0 15px 0;
  position: relative;
}

.chemicalHolder + .chemicalHolder {
  margin-top: 15px;
  border-top: 1px solid var(--color-border);
}

.buttonDel {
  position: absolute;
  right: 0;
  top: 10px;
}

:global(.ui.dropdown .menu > .item.restProducts) {
  background-color: #0ba359 !important;
}
:global(.ui.dropdown .menu > .item.restProductsHeaders) {
  background-color: #76010d !important;
  pointer-events: none !important;
}
:global(.ui.dropdown .menu > .item.selectedShedProductsHeaders) {
  pointer-events: none !important;
}

.textButton:global(.ui.button) {
  color: var(--color-green) !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButton:global(.ui.button):hover {
  color: #156941 !important;
  background: none;
  padding: 0;
  box-shadow: none;
  margin: 0 !important;
}

.textButtonContainer {
  margin-left: 0 !important;
  font-family: "Roboto" !important;
  margin-top: 8px;
  padding-right: 13px !important;
  width: 58% !important;
  display: flex !important;
  justify-content: end;
}
